import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {Grid, Row} from 'react-bootstrap';
import {compose, lifecycle, pure, withHandlers, withProps, withStateHandlers} from 'recompose';
import withLoading from '../../../../../hoc/withLoading';
import {connectMeAndTeamsAndConstantsContext} from '../../../../../hoc/withMeAndTeamsAndConstants';
import withCreateCustomerMutation from '../../../../../operations/Customer/Create';
import saveCustomer from '../../../../../operations/Customer/Save';
import withUpdateCustomerMutation from '../../../../../operations/Customer/Update';
import jsRoutes from '../../../../../routes/routes';
import {clone} from '../../../../../utils/functions';
import {errorMsg, successMsg} from '../../../../../utils/Notify';
import {withBankInfosCreationMutation} from './Sepa/SepaForm';
import {getSubscriberInput} from './Sepa/SubscriberForm';
import ButtonToolbarEdit from './ButtonToolbar';
import DeadlinesInfos from './DeadlinesInfos';
import EndOfContractInfos from './EndOfContractInfos';
import History from './History';
import InstallationInfos from './InstallationInfos';
import Order3xPaymentInfos from './Order3xPaymentInfos';
import OrderInfos from './OrderInfos';
import {saveSubscription, submitSubscriptionState} from './SubscriptionBlock/Operations';
import SubscriptionInfos from './SubscriptionInfos';
import SubscriptionStateEdit from './SubscriptionStateEdit';
import SubscriptionStateDeleteLast from './SubscriptionStateDeleteLast';
import computeProAbonoUrl from '../../../../../utils/ProAbonoUrl';

moment.locale('fr');

const SubscriptionBlockPure = ({
                                 currentEditedSubscriptionState,
                                 currentSubscriptionStateErrorIndexes,
                                 currentSubscriptionStateIndex,
                                 deleteSubscriptionState,
                                 deleteLastSubscriptionState,
                                 lastSubscriptionStateToDelete,
                                 handleDeleteLastSubscriptionStateCancel,
                                 handleDeleteLastSubscriptionStateSubmitted,
                                 editSubscriber,
                                 editSubscriptionState,
                                 partialTerminationFill,
                                 savedSubscription,
                                 somethingHasBeenModified,
                                 subscription,
                                 handleBreakageWarrantyChange,
                                 handleCancel,
                                 handleChange,
                                 handleDateChange,
                                 handleSave,
                                 handleStringChange,
                                 handleSubmitSubscriptionState,
                                 onHideClick,
                               }) => (
  <Grid fluid>
    <Row>
      <SubscriptionStateEdit currentEditedSubscriptionState={currentEditedSubscriptionState}
                             subscription={subscription}
                             onSubmitClick={handleSubmitSubscriptionState}
                             onHideClick={onHideClick}/>
      <SubscriptionStateDeleteLast lastSubscriptionStateToDelete={lastSubscriptionStateToDelete}
                                   handleDeleteLastSubscriptionStateCancel={handleDeleteLastSubscriptionStateCancel}
                                   handleDeleteLastSubscriptionStateSubmitted={handleDeleteLastSubscriptionStateSubmitted} />

      <ButtonToolbarEdit currentSubscriptionStateErrorIndexes={currentSubscriptionStateErrorIndexes}
                         partialTerminationFill={partialTerminationFill}
                         somethingHasBeenModified={somethingHasBeenModified}
                         handleCancel={handleCancel}
                         handleSave={handleSave}/>

      <SubscriptionInfos savedSubscription={savedSubscription}
                         subscription={subscription}
                         somethingHasBeenModified={somethingHasBeenModified}
                         handleBreakageWarrantyChange={handleBreakageWarrantyChange}
                         handleDateChange={handleDateChange}
                         handleStringChange={handleStringChange}/>

      <hr/>
      <OrderInfos subscription={subscription}/>
      <Order3xPaymentInfos subscription={subscription}/>
      <hr/>
      <History currentSubscriptionStateIndex={currentSubscriptionStateIndex}
               currentSubscriptionStateErrorIndexes={currentSubscriptionStateErrorIndexes}
               subscription={subscription}
               deleteSubscriptionState={deleteSubscriptionState}
               deleteLastSubscriptionState={deleteLastSubscriptionState}
               editSubscriber={editSubscriber}
               editSubscriptionState={editSubscriptionState}/>
      <hr/>
      <InstallationInfos subscription={subscription}
                         handleDateChange={handleDateChange}/>
      <hr/>
      <DeadlinesInfos subscription={subscription}
                      handleBreakageInsuranceChange={handleBreakageWarrantyChange}
                      handleDateChange={handleDateChange}/>
      <hr/>
      <EndOfContractInfos subscription={subscription}
                          handleChange={handleChange}
                          handleDateChange={handleDateChange}
                          handleStringChange={handleStringChange}/>

      <ButtonToolbarEdit currentSubscriptionStateErrorIndexes={currentSubscriptionStateErrorIndexes}
                         partialTerminationFill={partialTerminationFill}
                         somethingHasBeenModified={somethingHasBeenModified}
                         handleCancel={handleCancel}
                         handleSave={handleSave}/>
    </Row>
  </Grid>
);

SubscriptionBlockPure.propTypes = {
  currentSubscriptionStateIndex: PropTypes.number,
  currentEditedSubscriptionState: PropTypes.number,
  currentSubscriptionStateErrorIndexes: PropTypes.array,
  subscription: PropTypes.object,
  partialTerminationFill: PropTypes.bool,
  somethingHasBeenModified: PropTypes.bool,
  deleteSubscriptionState: PropTypes.func,
  deleteLastSubscriptionState: PropTypes.func,
  lastSubscriptionStateToDelete: PropTypes.object,
  handleDeleteLastSubscriptionStateCancel: PropTypes.func,
  handleDeleteLastSubscriptionStateSubmitted: PropTypes.func,
  editSubscriptionState: PropTypes.func,
  handleCancel: PropTypes.func,
  handleChange: PropTypes.func,
  handleDateChange: PropTypes.func,
  handleSave: PropTypes.func,
  handleStringChange: PropTypes.func,
  handleSubmitSubscriptionState: PropTypes.func,
  onHideClick: PropTypes.func,
};

const withStates = withStateHandlers(
  () => ({
    currentEditedSubscriber: null,
    currentEditedSubscriptionState: null,
    savedSubscription: {},
    subscription: {},
    lastSubscriptionStateToDelete: null,
  }),
  {
    deleteSubscriptionState: ({subscription}) => (index) => {
      subscription.metadata.subscriptionStates.splice(index, 1);
      return {subscription: {...subscription}};
    },
    deleteLastSubscriptionState: ({subscription}) => () => {
        return {lastSubscriptionStateToDelete: subscription.metadata.subscriptionStates[0]};
    },
    handleDeleteLastSubscriptionStateCancel: () => () => ({
        lastSubscriptionStateToDelete: null,
    }),
    editSubscriber: () => subscriberCustomerID => ({
      currentEditedSubscriber: subscriberCustomerID,
    }),
    editSubscriptionState: () => (index) => ({
      currentEditedSubscriptionState: index,
    }),
    handleBreakageWarrantyChange: ({subscription}) => (propertyName, value) => ({
      subscription: {
        ...subscription,
        breakageWarranty: {
          ...subscription.breakageWarranty,
          [propertyName]: value,
        },
      },
    }),
    handleCancel: ({savedSubscription}) => () => ({
      subscription: {...savedSubscription},
    }),
    handleChange: ({savedSubscription, subscription}) => (propertyName, value, setNull) => {
      if (value || value === false || value === 0) {
        _.set(subscription, propertyName, value);

      } else if (_.has(savedSubscription, propertyName)) {
        if (setNull === true) {
          _.set(subscription, propertyName, null);
        } else {
          _.set(subscription, propertyName, '');
        }
      } else {
        _.unset(subscription, propertyName);
      }

      return {subscription: {...subscription}};
    },
    handleMetadataChange: ({savedSubscription, subscription}) => (propertyName, value, setNull) => {
      if (value || value === false || value === 0) {
        _.set(subscription.metadata, propertyName, value);

      } else if (_.has(savedSubscription.metadata, propertyName)) {
        if (setNull === true) {
          _.set(subscription.metadata, propertyName, null);
        } else {
          _.set(subscription.metadata, propertyName, '');
        }
      } else {
        _.unset(subscription.metadata, propertyName);
      }

      return {subscription: {...subscription}};
    },
    handleGetSubscription: () => (subscription) => ({
      savedSubscription: subscription,
      subscription: clone(subscription),
    }),
    onHideClick: () => () => ({currentEditedSubscriptionState: null}),
    handleSubmitSubscriptionState: submitSubscriptionState,
  },
);

const handlers = withHandlers({
  handleStringChange: ({handleChange}) => (propertyName, event) => {
    handleChange(propertyName, event.target.value);
  },

  handleDateChange: ({handleChange, handleMetadataChange}) => (propertyName, value) => {
    const arrProps = propertyName.split('.');
    const formattedValue = value ? moment(value).valueOf() : value;

    if (arrProps[0] === 'metadata' && arrProps.length === 2) {
      handleMetadataChange(arrProps[1], formattedValue, true);
    } else {
      handleChange(propertyName, formattedValue, true);
    }
  },

  handleDeleteLastSubscriptionStateSubmitted: props => () => {
      props.handleDeleteLastSubscriptionStateCancel();
      props.loadSubscription();
      window.open(computeProAbonoUrl(props.contractNumber));
  },
  handleSave: props => () => {
    const {contractNumber, subscription, handleCreateBankID, setLoading} = props;
    const {bankID: createBankID, current: createSubscriber} = subscription?.metadata?.subscriptionStates?.[0];

    if (createSubscriber) {
      const subscriberInput = getSubscriberInput(createSubscriber);
      const subscriberSaveErrorFn = () => setLoading(false, () => errorMsg('Erreur lors de la sauvegarde du souscripteur'));

      saveCustomer(
        subscriberInput,
        null,
        props,
        createdSubscriber => {
          const {id} = createdSubscriber || {};
          if (!id) {
            return subscriberSaveErrorFn();
          }

          handleCreateBankID(
            createBankID,
            contractNumber,
            id,
            setLoading,
            (store, {data}) => {
              const {customer} = data?.bankIDCreate || {};
              if (!customer?.id) {
                return setLoading(false, () => errorMsg('Erreur lors de la sauvegarde du mandat SEPA'));
              }

              saveSubscription(
                props,
                customer?.id,
                () => successMsg('Souscripteur et mandat SEPA sauvegardés'),
              );
            },
          );
        },
        subscriberSaveErrorFn,
      );
    } else {
      saveSubscription(props);
    }
  },
});

const withLoadSubscriptionHandler = withHandlers({
        loadSubscription: (props) => () => {
            jsRoutes.controllers.backoffice.SupportV2Controller.getSubscription(props.contractNumber).ajax({
                dataType: 'json',
                processData: false,
            }).then(
                response => props.handleGetSubscription(response),
                () => props.setLoading(false),
            );
        },
    }
);

const withLifecycle = lifecycle({
  componentDidMount() {
    this.props.loadSubscription();
  },
});

const precalculatedProps = withProps(
  ({subscription}) => {
    let currentSubscriptionStateIndex = -1;
    let currentSubscriptionStateErrorIndexes = [];

    if (subscription?.metadata?.subscriptionStates) {
      for (let i = 0; i < subscription.metadata.subscriptionStates.length; i++) {
        let subscriptionState = subscription.metadata.subscriptionStates[i];
        if (!subscriptionState.dateBegin || moment(subscriptionState.dateBegin).isBefore(moment())) {
          return i;
        }
      }

      for (let i = 0; i < subscription.metadata.subscriptionStates.length - 1; i++) {
        let subscriptionState = subscription.metadata.subscriptionStates[i];
        let nextSubscriptionState = subscription.metadata.subscriptionStates[i + 1];
        if (subscriptionState.dateBegin > subscriptionState.dateEnd) {
          currentSubscriptionStateErrorIndexes.push(i);
        }
        if (subscriptionState.dateBegin < nextSubscriptionState.dateEnd) {
          currentSubscriptionStateErrorIndexes.push(i);
          currentSubscriptionStateErrorIndexes.push(i + 1);
        }
      }
    }

    return {
      currentSubscriptionStateIndex,
      currentSubscriptionStateErrorIndexes,
    };
  },
);

const isTerminationPartiallyFilled = (terminationAsk, terminationReason) => !!terminationAsk && !terminationReason;

const calculatedProps = withProps(
  ({savedSubscription, subscription}) => ({
    partialTerminationFill: isTerminationPartiallyFilled(subscription.terminationAsk, subscription.terminationReason),
    somethingHasBeenModified: JSON.stringify(subscription) !== JSON.stringify(savedSubscription),
  }),
);

const SubscriptionBlock = compose(
  connectMeAndTeamsAndConstantsContext,
  precalculatedProps,
  withStates,
  withLoading,
  withBankInfosCreationMutation,
  withCreateCustomerMutation,
  withUpdateCustomerMutation,
  withLoadSubscriptionHandler,
  handlers,
  withLifecycle,
  calculatedProps,
  pure,
)(SubscriptionBlockPure);

SubscriptionBlock.propTypes = {
  contractNumber: PropTypes.string,
};

export default SubscriptionBlock;