import PropTypes from 'prop-types';
import React from 'react';
import {tsToFullDate} from '../../utils/Date';
import AskMetricUpdateButton from './MaintenanceTable/AskMetricUpdateButton';
import MaintenanceStatus from './MaintenanceTable/MaintenanceStatus';
import MaintenanceThreshold from './MaintenanceTable/MaintenanceThreshold';

const MaintenanceTableRow = ({ metric }) => (
  <tr>
    <td className="dark-grey first-cell">
      <MaintenanceStatus status={metric.status} />
    </td>
    <td className="dark-grey">{metric.name}</td>
    <td className="light-grey">{metric.prettyValue || tsToFullDate(metric.value)}</td>{/* This field displays the
    critical value we want to monitor. It can be anything: a count, a status, a date... It is most likely already
    formatted in a human-readable way in metric.prettyValue. For some metric though that property will be empty and then
     metric.value will contain the raw value which we will have to format in place. To this date, such cases are only
     timestamp based metrics (and format is locale and timezone dependant) */}
    <td className="dark-grey">
      <MaintenanceThreshold
        errorThreshold={metric.prettyErrorThreshold}
        warningThreshold={metric.prettyWarningThreshold}
      />
    </td>
    <td className="light-grey">{tsToFullDate(metric.lastUpdated)}</td>
    <td className="black last-cell">
        <AskMetricUpdateButton metric={metric} />
    </td>
  </tr>
);

MaintenanceTableRow.propTypes = {
  metric: PropTypes.object,
};

export default MaintenanceTableRow;