import gql from 'graphql-tag';

export const METRIC_FRAGMENT = gql`
    fragment metricFields on Metric {
        id
        status
        metricType
        name
        value
        prettyValue
        prettyErrorThreshold
        prettyWarningThreshold
        lastModified
        lastUpdated
    }
`;

export const GET_METRICS = gql`
    query MetricsQuery {
        getMetrics {
            ... on GetMetricsErrorOutput {
                errorReason
                __typename
            }
            ... on GetMetricsOutput {
                metrics {
                    ...metricFields
                }
                __typename
            }
            __typename
        }
    }
    ${METRIC_FRAGMENT}
`;

export const ASK_UPDATE_METRIC_MUTATION = gql`
    mutation AskUpdateMetricMutation($metricType: MetricType!) {
        askMetricUpdate(metricType: $metricType) {
            ... on AskMetricUpdateErrorOutput {
                errorReason
                __typename
            }
            ... on AskMetricUpdateOutput {
                metric {
                    ...metricFields
                }
                __typename
            }
        }
    }
    ${METRIC_FRAGMENT}
`;