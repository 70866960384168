import React from 'react';
import PropTypes from 'prop-types';
import {branch, compose, pure, renderNothing, withProps} from 'recompose';
import { Label } from 'react-bootstrap';
import moment from 'moment';

import {BREAKAGE_WARRANTY_STATUS_TYPES} from '../../../../../utils/constants';

moment.locale('fr');

const BreakageWarrantyLabelPure = ({ label, style }) =>
    <Label bsStyle={style}>{label}</Label>;

BreakageWarrantyLabelPure.propTypes = {
    label: PropTypes.string,
    style: PropTypes.string,
};

const calculatedProps = withProps(
    ({ breakageWarranty }) => {
        let label,
            style;

        if(breakageWarranty?.status) {
            switch (breakageWarranty.status) {
                case BREAKAGE_WARRANTY_STATUS_TYPES.ENABLED.value:
                    label = 'Garantie casse et vol';
                    style = 'success';
                    break;
                case BREAKAGE_WARRANTY_STATUS_TYPES.SUBSCRIBED.value:
                    label = 'Garantie casse et vol souscrite';
                    style = 'warning';
                    break;
                case BREAKAGE_WARRANTY_STATUS_TYPES.TERMINATED.value:
                case BREAKAGE_WARRANTY_STATUS_TYPES.DISABLED.value:
                case BREAKAGE_WARRANTY_STATUS_TYPES.UNDEFINED.value:
                default:
                    label = 'Sans garantie casse et vol';
                    style = 'danger';
                    break;
            }
        }

        return {
            label,
            style,
        };
    }
);

const BreakageWarrantyLabel = compose(
    branch(
        ({ breakageWarranty }) => !breakageWarranty,
        renderNothing,
    ),
    calculatedProps,
    pure,
)(BreakageWarrantyLabelPure);

BreakageWarrantyLabel.propTypes = {
    breakageWarranty: PropTypes.object,
};

export default BreakageWarrantyLabel;