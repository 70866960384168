const EMAIL_REGEXP = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

const FRENCH_DAY_LABELS = [
    'Dim',
    'Lun',
    'Mar',
    'Mer',
    'Jeu',
    'Ven',
    'Sam',
];

const FRENCH_MONTH_LABELS = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
];

const USER_TYPES = {
    1: 'CCA',
    2: 'OTHER',
};

const DEVICE_RESET_MODES = [{
    label: 'Non',
    value: 'NONE',
}, {
    label: 'Une fois',
    value: 'ONCE',
}, {
    label: 'Toujours',
    value: 'ALWAYS',
}];

const USER_TEAMS = {
    N1: '0',
    VALIDATION: '1',
    N2_COMMERCIAL: '2',
    N2_TECHNIQUE: '3',
    N3_TECHNIQUE: '4',
    DAPO: '5',
    LPM: '6',
    LIVRAISON: '7',
    SFP: '8',
    SAV: '9',
    CAMPAIGN: '10',
    CONTRACT: '11',
    CHAT: '12',
    BO: '13',
    B2B: '14',
};

const TICKET_SUBJECTS = {
    RGPD: '-5',
    RETOUR_TAB_SUIVI_AUTO: '-4',
    SR_SUIVI_AUTO: '-3',
    SAV_SUIVI_AUTO: '-2',
    INTERNAL_ERROR: '-1',
    LEGACY: '0',
    PROSPECT: '1',
    INSTALLATION_FACTEUR: '2',
    PREPARATION: '3',
    CONTRAT: '4',
    THREEG: '5',
    LIVRAISON: '6',
    WELCOME_CALL: '7',
    COURRIER: '8',
    SATISFAIT_REMBOURSE: '9',
    SAV: '10',
    UTILISATION: '11',
    ABONNEMENT: '12',
    RESILIATION: '13',
    INFORMATION: '14',
    FORMATION_IND: '15',
    RENOUV_PASS: '16',
    DUPLICATA: '17',
    RETOUR_TABLETTE: '18',
    SUIVI_DOSSIER: '19',
    RENOUV_TAB: '20',
    ASSISTANCE_INSTALLATION: '21',
    SINISTRE: '22',
    DEMANDE_EVOLUTION: '23'
};

const AUTOMATED_TICKET_SUBJECTS = [
    TICKET_SUBJECTS.THREEG,
    TICKET_SUBJECTS.INFORMATION,
    TICKET_SUBJECTS.INSTALLATION_FACTEUR,
    TICKET_SUBJECTS.LIVRAISON,
    TICKET_SUBJECTS.PREPARATION,
];

const TICKET_RESULT_OTHER_REASONS = [
    '9.7', // SATISFAIT_REMBOURSE_AUTRE_MOTIF
    '10.7', // SAV_AUTRE_SAV
];

const TICKET_STATES = {
    TODO: '0',
    DOING: '1',
    DONE: '2',
};

const BREAKAGE_INSURANCE_STATUS_TYPES = {
    ENABLED: {
        label: 'Activée',
        value: 'enabled',
    },
    DISABLED: {
        label: 'Inactive',
        value: 'disabled',
    },
    SUBSCRIBED: {
        label: 'Souscrite',
        value: 'subscribed',
    },
    TERMINATED: {
        label: 'Résiliée',
        value: 'terminated',
    },
    UNDEFINED: {
        label: '',
        value: '',
    },
};

const BREAKAGE_WARRANTY_STATUS_TYPES = {
    ENABLED: {
        label: 'Activée',
        value: 'enabled',
    },
    DISABLED: {
        label: 'Inactive',
        value: 'disabled',
    },
    SUBSCRIBED: {
        label: 'Souscrite',
        value: 'subscribed',
    },
    TERMINATED: {
        label: 'Résiliée',
        value: 'terminated',
    },
    UNDEFINED: {
        label: '',
        value: '',
    },
};

const SAUVEGARDE_PREFIX = 'SAUVEGARDE ';

const TICKET_DUPLICATA_RESULTS = {
    'ERREUR_PROCESS': 'ERREUR PROCESS',
    'PERTE': 'PERTE',
    '17.6': 'SAV',
    '17.7': 'CASSE',
};

const TICKET_DUPLICATA_SUBRESULTS = {
    'ERREUR_PROCESS': {
        '17.1': 'PDC',
        '17.2': 'MODE LIVRAISON',
        '17.3': 'PREPARATION DAPO',
    },
    'PERTE': {
        '17.4': 'CHRONO',
        '17.5': 'PDC',
    },
};

const DEV = process.env.NODE_ENV === 'development';

const STAGING = window.STAGING;

const TOUCAN_URL = `${window.TOUCAN_URL || ''}/toucan`;

const TOUCAN_AUTHORIZATION = window.TOUCAN_AUTHORIZATION;

const ENABLE_LMR_FEATURES = window.ENABLE_LMR_FEATURES;

const ENABLE_IMMEDIATE_SUBSCRIPTION_SWITCH_FEATURE = window.ENABLE_IMMEDIATE_SUBSCRIPTION_SWITCH_FEATURE;

const CONTRACT_LAPOSTE = '00000000';
const CONTRACT_LIBRE = '00000001';
const CONTRACT_DETACHED = 'detached';

const isLibreOrLaPosteContract = contractNumber => contractNumber === CONTRACT_LAPOSTE || contractNumber === CONTRACT_LIBRE;

const GCM_ORDERS_TYPES = {
    MAINTENANCE: 'MAINTENANCE',
    OTHER: 'OTHER',
    PULL: 'PULL',
    TEAMVIEWER: 'teamviewer',
    UPDATE: 'UPDATE',
};

const CUSTOMER_TYPOLOGY_TYPES = {
    DEMO: 'DEMO',
    PART: 'PART',
    PRO: 'PRO',
};

const FSPLUS_SERVICE_TYPES = {
    INSTALLATION: 'SIMPLE',
    MISE_EN_MAIN: 'MISE EN MAIN',
};

const PROABONO_BACKOFFICE_URL = window.PROABONO_BACKOFFICE_URL;

export {
    DEVICE_RESET_MODES,
    EMAIL_REGEXP,
    FRENCH_DAY_LABELS,
    FRENCH_MONTH_LABELS,
    USER_TYPES,
    USER_TEAMS,
    TICKET_SUBJECTS,
    AUTOMATED_TICKET_SUBJECTS,
    TICKET_STATES,
    BREAKAGE_INSURANCE_STATUS_TYPES,
    BREAKAGE_WARRANTY_STATUS_TYPES,
    SAUVEGARDE_PREFIX,
    TICKET_DUPLICATA_RESULTS,
    TICKET_DUPLICATA_SUBRESULTS,
    TICKET_RESULT_OTHER_REASONS,
    DEV,
    STAGING,
    TOUCAN_URL,
    TOUCAN_AUTHORIZATION,
    ENABLE_LMR_FEATURES,
    CONTRACT_LAPOSTE,
    CONTRACT_LIBRE,
    CONTRACT_DETACHED,
    isLibreOrLaPosteContract,
    GCM_ORDERS_TYPES,
    CUSTOMER_TYPOLOGY_TYPES,
    FSPLUS_SERVICE_TYPES,
    PROABONO_BACKOFFICE_URL,
    ENABLE_IMMEDIATE_SUBSCRIPTION_SWITCH_FEATURE
};