import React from 'react';
import {Col, ControlLabel, FormControl, FormGroup, Row} from 'react-bootstrap';
import {compose, pure, withProps} from 'recompose';
import PropTypes from 'prop-types';
import moment from 'moment';

import {BREAKAGE_INSURANCE_STATUS_TYPES, BREAKAGE_WARRANTY_STATUS_TYPES} from '../../../../../utils/constants';
import {isN2OrHigher} from '../../../../../utils/functions';
import {SUBSCRIPTION_STATUS_TYPES} from '../../../../../utils/Subscription';
import {connectMeAndTeamsAndConstantsContext} from '../../../../../hoc/withMeAndTeamsAndConstants';
import DateInput from './DateInput';

const SubscriptionInfosPure = ({
                                   initializedDate,
                                   isAuthorized,
                                   n1SubscriptionTypeValue,
                                   savedSubscription,
                                   subscription,
                                   webUserJson,
                                   handleBreakageWarrantyChange,
                                   handleDateChange,
                                   handleStringChange,
                                   somethingHasBeenModified,
}) => (
    <>
        <h4>Informations</h4>
        <Row>
            <Col md={4}>
                <FormGroup>
                    <ControlLabel>Type d'abonnement</ControlLabel>
                    <FormControl bsSize='sm' type='text' value={n1SubscriptionTypeValue} readOnly/>
                </FormGroup>
            </Col>
            <Col md={4}>
                <FormGroup>
                    <ControlLabel>Abonnement</ControlLabel>
                    <FormControl componentClass='select'
                                 value={subscription.subscriptionStatusType}
                                 onChange={event => handleStringChange('subscriptionStatusType', event)}
                                 disabled={!isAuthorized}
                                 bsSize='sm'>
                        {
                            Object.keys(SUBSCRIPTION_STATUS_TYPES).map(
                                key =>
                                    <option key={key} value={SUBSCRIPTION_STATUS_TYPES[key].value}>{SUBSCRIPTION_STATUS_TYPES[key].label}</option>
                            )
                        }
                    </FormControl>
                </FormGroup>
            </Col>
            <Col md={3}>
                <FormGroup>
                    <ControlLabel>Date init</ControlLabel>
                    <FormControl type='text' value={initializedDate} bsSize='sm' readOnly/>
                </FormGroup>
            </Col>
        </Row>

        <Row>
            <Col md={3}>
                <FormGroup>
                    <ControlLabel>Fin de garantie constructeur</ControlLabel>
                    <DateInput bsSize='sm'
                               modificationEnabled={isAuthorized}
                               value={subscription.warranty?.baseEnd}
                               handleChange={event => handleDateChange('warranty.baseEnd', event)} />
                </FormGroup>
            </Col>
            <Col md={3}>
                <FormGroup>
                    <ControlLabel>Extension de garantie</ControlLabel>
                    {subscription.warranty?.extensionEnd ?
                        <DateInput bsSize='sm'
                                   modificationEnabled={isAuthorized}
                                   value={subscription.warranty?.extensionEnd}
                                   handleChange={event => handleDateChange('warranty.extensionEnd', event)} /> :
                        'Non'
                    }
                </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col md={4}>
                {/* Old break insurance, still readonly */}
                {!somethingHasBeenModified && <FormGroup>
                    <ControlLabel>Assurance casse</ControlLabel>
                    <FormControl componentClass='select'
                                 value={subscription?.breakageInsurance?.status || BREAKAGE_INSURANCE_STATUS_TYPES.DISABLED.value}
                                 disabled={true}
                                 bsSize='sm'>
                        {
                            Object.keys(BREAKAGE_INSURANCE_STATUS_TYPES)
                                  .filter(key => key !== 'UNDEFINED')
                                  .map(
                                key =>
                                    <option key={key}
                                            value={BREAKAGE_INSURANCE_STATUS_TYPES[key].value}
                                            disabled={BREAKAGE_INSURANCE_STATUS_TYPES[key].value !== BREAKAGE_INSURANCE_STATUS_TYPES.ENABLED.value}>
                                        {BREAKAGE_INSURANCE_STATUS_TYPES[key].label}
                                    </option>
                            )
                        }
                    </FormControl>
                </FormGroup>}
                <FormGroup>
                    <ControlLabel>Garantie casse</ControlLabel>
                    <FormControl componentClass='select'
                                 value={subscription?.breakageWarranty?.status || BREAKAGE_WARRANTY_STATUS_TYPES.DISABLED.value}
                                 onChange={event => handleBreakageWarrantyChange('status', event.target.value)}
                                 disabled={
                                    !isN2OrHigher(webUserJson) ||
                                    savedSubscription?.breakageWarranty?.status !== BREAKAGE_WARRANTY_STATUS_TYPES.TERMINATED.value
                                }
                                 bsSize='sm'>
                        {
                            Object.keys(BREAKAGE_WARRANTY_STATUS_TYPES)
                                  .filter(key => key !== 'UNDEFINED')
                                  .map(
                                key =>
                                    <option key={key}
                                            value={BREAKAGE_WARRANTY_STATUS_TYPES[key].value}
                                            disabled={BREAKAGE_WARRANTY_STATUS_TYPES[key].value !== BREAKAGE_WARRANTY_STATUS_TYPES.ENABLED.value}>
                                        {BREAKAGE_WARRANTY_STATUS_TYPES[key].label}
                                    </option>
                            )
                        }
                    </FormControl>
                </FormGroup>
            </Col>
            {
                [
                    BREAKAGE_INSURANCE_STATUS_TYPES.ENABLED.value,
                    BREAKAGE_INSURANCE_STATUS_TYPES.SUBSCRIBED.value,
                    BREAKAGE_INSURANCE_STATUS_TYPES.TERMINATED.value,
                ].includes(subscription?.breakageInsurance?.status) &&
                <Col md={5}>
                    <FormGroup>
                        <ControlLabel>Date d'activation de l'option casse</ControlLabel>
                        <DateInput bsSize='sm'
                                   modificationEnabled={false}
                                   value={subscription?.breakageInsurance?.subscriptionStart}
                                   handleChange={event => handleBreakageWarrantyChange('subscriptionStart', event.target.value ? moment(event.target.value).valueOf() : event.target.value)} />
                    </FormGroup>
                </Col>
            }
        </Row>
    </>
);

SubscriptionInfosPure.propTypes = {
    initializedDate: PropTypes.string,
    isAuthorized: PropTypes.bool,
    n1SubscriptionTypeValue: PropTypes.string,
    subscription: PropTypes.object,
    handleStringChange: PropTypes.func,
    somethingHasBeenModified: PropTypes.bool,
};

const getCurrentSubscriptionStateType = subscription => {
    if (subscription?.metadata?.subscriptionStates) {
        for (let i = 0; i < subscription.metadata.subscriptionStates.length; i++) {
            const subscriptionState = subscription.metadata.subscriptionStates[i];

            if (!subscriptionState.dateBegin || subscriptionState.dateBegin < Date.now()) {
                return subscriptionState.type;
            }
        }
    }

    return subscription?.oldType || '';
};

const withCalculatedProps = withProps(
    ({ subscription, webUserJson }) => ({
        initializedDate: subscription.initializedDate ? moment(subscription.initializedDate).format('L') : '',
        isAuthorized: isN2OrHigher(webUserJson),
        n1SubscriptionTypeValue: getCurrentSubscriptionStateType(subscription),
    }),
);

const SubscriptionInfos = compose(
    connectMeAndTeamsAndConstantsContext,
    withCalculatedProps,
    pure,
)(SubscriptionInfosPure);

SubscriptionInfos.propTypes = {
    savedSubscription: PropTypes.object,
    subscription: PropTypes.object,
    handleBreakageWarrantyChange: PropTypes.func,
    handleDateChange: PropTypes.func,
    handleStringChange: PropTypes.func,
    somethingHasBeenModified: PropTypes.bool,
};

export default SubscriptionInfos;